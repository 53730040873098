"use client";

import React, { useCallback, useContext, useEffect, useState } from "react";

import * as Sentry from "@sentry/nextjs";

import type { UserEntity } from "@/hl-common/types/api/Users";

import Message from "@/components/Message";
import { Button } from "@/components/forms/Button";

import type {
  VerifyOtpRequest,
  VerifyOtpResponse,
} from "@/hl-common/types/api/VerifyOtp";

import { auth_channel } from "@/hl-common/types/api/PrismaEnums";
import type { RegisterRequest } from "@/hl-common/types/api/Register";
import type {
  VerifyPasswordRequest,
  VerifyPasswordResponse,
} from "@/hl-common/types/api/VerifyPassword";
import { useRouter } from "next/navigation";
import type { UserToken } from "../hl-common/types/UserToken";
import { ModalContext } from "./ModalContext";
import {
  logout,
  me,
  registerUser,
  verifyOtp,
  verifyPassword,
} from "./api/client";
import {
  ApiError,
  getErrorMessage,
  isInvalidCredentialError,
} from "./api/fetch";

export const userContextInitialValue = {
  loadUser: async () => {},
  user: {} as UserEntity,
  userLoading: false,
  initialized: false,
  handleUserRegister: async (user: RegisterRequest) => {
    return {} as UserEntity;
  },
  handleLogin: async (body: VerifyOtpRequest) => {
    return {} as VerifyOtpResponse;
  },
  handlePassword: async (body: VerifyPasswordRequest) => {
    return {} as VerifyPasswordResponse;
  },
  handleLogout: () => {},
};

export const UserContext = React.createContext(userContextInitialValue);

// prefill some empty user properties to avoid undefined errors e.g. on logout
const emptyUser: UserEntity = {
  id: 0,
  username: "",
  first: "",
  last: "",
  email: "",
  phone: "",
  provider: "",
  confirmed: false,
  blocked: false,
  createdAt: new Date(),
  updatedAt: new Date(),
  termsAndConditions: false,
  authChannel: auth_channel.WHATSAPP,
  role: {
    id: 0,
    name: "",
    type: "",
  },
};

export const WithUser = ({
  serverSideUser,
  children,
}: { serverSideUser: UserToken | null; children: React.ReactNode }) => {
  const { replace } = useRouter();
  const { setModal } = useContext(ModalContext);
  const [user, setUser] = useState(emptyUser);

  const [initialized, setInitialized] = useState(false);
  const [userLoading, setUserLoading] = useState(false);

  const handleUserRegister = useCallback(async (req: RegisterRequest) => {
    const u = await registerUser(req);
    setUser(u);
    return u;
  }, []);

  const handleLogin = useCallback(async (body: VerifyOtpRequest) => {
    const resp = await verifyOtp(body);
    setUser(resp.user);
    return resp;
  }, []);

  const handlePassword = useCallback(async (body: { password: string }) => {
    const resp = await verifyPassword(body);
    setUser(resp.user);
    return resp;
  }, []);

  const handleLogout = useCallback(async () => {
    const resp = await logout();
    if (resp.success) {
      // force full refresh on logout to avoid stale layouts
      // note: useRouter().refresh() is insufficient to clear the client-side router cache!
      window.location.href = "/login";
    }
  }, []);

  const loadUser = useCallback(async () => {
    setUserLoading(true);
    try {
      const user = await me();
      setUser(user);
    } catch (error) {
      if (
        error instanceof ApiError &&
        (error.statusCode === 401 || // nestjs anon
          error.statusCode === 403 || // sentry anon
          isInvalidCredentialError(error)) // expired/malformed token
      ) {
        // swallow the expected errors
      } else {
        setModal(<UserError error={error} />);
      }
    }

    setUserLoading(false);
    setInitialized(true);
  }, [setModal]);

  // fetch the user on mount, if the server parsed their cookie
  // TODO: maybe do this fetch from next backend?
  useEffect(() => {
    if (serverSideUser) {
      loadUser();
    }
  }, [serverSideUser, loadUser]);

  // set user in sentry scope
  useEffect(() => {
    const scope = Sentry.getCurrentScope();
    if (user.id) {
      scope.setUser({
        id: user.id,
      });
    } else {
      scope.setUser(null);
    }
  }, [user]);

  return (
    <UserContext.Provider
      value={{
        loadUser,
        user,
        userLoading,
        initialized,
        handleUserRegister,
        handleLogin,
        handlePassword,
        handleLogout,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

const UserError = ({ error }: { error: unknown }) => {
  const { close } = useContext(ModalContext);
  return (
    <>
      <Message error>Error authenticating: {getErrorMessage(error)}</Message>
      <Button secondary onClick={close}>
        Close
      </Button>
    </>
  );
};
